import { Injectable } from "@angular/core";
import { ENABLE_CHAT, EnableFeature } from "@ankaadia/ankaadia-shared";
import { environment } from "apps/frontend/src/environments/environment";
import { BehaviorSubject, EMPTY, filter, from, Observable, startWith, switchMap } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

export function isChatEnabled(): boolean {
  return ENABLE_CHAT && environment.cometChatAppId && environment.cometChatAuthKey;
}

@Injectable({ providedIn: "root" })
@EnableFeature(ENABLE_CHAT)
export class ChatInitService {
  initialized = new BehaviorSubject(false);
  count = new BehaviorSubject(0);
  authToken: string = undefined;

  constructor(private readonly settingsService: SettingsService) {}

  initChat(): Observable<void> {
    if (!isChatEnabled()) {
      return EMPTY;
    }

    return this.settingsService.isAuthorized$.pipe(
      startWith(this.settingsService.isAuthorized$.value),
      filter((isAuthorized) => isAuthorized),
      switchMap(() => from(this.initChatInternal()))
    );
  }

  private async initChatInternal(): Promise<void> {
    // Make the initial bunde size smaller...

    const [{ CometChatUIKit }, { UIKitSettingsBuilder }, { CometChat }] = await Promise.all([
      import("@cometchat/chat-uikit-angular"),
      import("@cometchat/uikit-shared"),
      import("@cometchat/chat-sdk-javascript"),
    ]);

    const UIKitSettings = new UIKitSettingsBuilder()
      .setAppId(environment.cometChatAppId)
      .setRegion("eu")
      .setAuthKey(environment.cometChatAuthKey)
      .subscribePresenceForAllUsers()
      .build();

    //Initialize CometChat UI Kit
    await CometChatUIKit.init(UIKitSettings);
    try {
      const user = await CometChatUIKit.login({ uid: this.settingsService.userOrCandidateId });
      this.authToken = (<any>user)?.authToken;
    } catch (e) {
      // User doesn't have an account yet... create one :)
      // Will be done by the backend in the future, for development purposes we do it here
      const newUser = new CometChat.User(this.settingsService.userOrCandidateId);
      newUser.setName(this.settingsService.userDisplayName);
      newUser.setTags([this.settingsService.organizationId]);

      await CometChatUIKit.createUser(newUser);
      const user = await CometChatUIKit.login({ uid: this.settingsService.userOrCandidateId }).catch();
      this.authToken = (<any>user)?.authToken;
    }

    this.initialized.next(true);
  }
}
